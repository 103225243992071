<template>
  <div id="home">
    <div class="container">
      <div class="body">
        <div class="image">
          <img src="../assets/header.jpg" alt="Indigenous Tourism Resource Portal" uk-img />
        </div>
        <div class="intro">
          <div class="uk-section-small uk-text-center">
            <p class="uk-text-center">
                <a href="https://turismoindigena.net/" class="uk-button uk-button-primary" target="_blank">
                  Ver versión en Español
                </a>
              </p>  
            <h1 class="uk-margin-remove">Indigenous Tourism Resource Portal</h1>
            <h2 class="uk-margin-remove uk-text-bold">Call for Resources</h2>
            <p class="subtitle">
              Please help us build the largest database <br />
              of Indigenous tourism resources ever created
            </p>
          </div>
        </div>
        <div id="description" class="uk-background-muted">
          <div class="uk-section-small">
            <div class="uk-container">
              <p class="uk-text-bold">
                WHAT IS THE INDIGENOUS TOURISM RESOURCE PORTAL?
              </p>
              <p>
                <span class="uk-text-bold">The Indigenous Tourism Collaborative of the Americas
                  (ITCA)</span>
                is assembling an Indigenous Tourism Resource Portal, an easily
                accessible, comprehensive inventory of tools and resources to
                help Indigenous peoples and their stakeholders and partners
                develop and better manage tourism. The Portal will be leveraged
                to achieve sustainable Indigenous tourism throughout the
                Americas &#8212; US, Canada, Mexico, Central America, Caribbean, and
                South America &#8212; to rebuild the sector following the COVID-19
                pandemic 2020-2022.
              </p>
            </div>
          </div>
        </div>
        <div id="cta">
          <div class="uk-section-small">
            <div class="uk-container">
              <p>
                <span class="uk-text-bold">ITCA</span> is an advisory network of
                Indigenous leaders and non-Indigenous tourism leaders working
                together to recover from COVID-19, build capacity and resilience
                in Indigenous tourism and gain respect and inclusion for
                Indigenous tourism leadership in governments and the tourism
                industry.
              </p>
            </div>
          </div>
        </div>
        <div id="large">
          <div class="uk-section-small">
            <div class="uk-container">
              <h3 class="uk-text-center">
                Interested in Helping Indigenous Communities by Submitting a
                Resource?
              </h3>
              <h4 class="uk-text-center uk-text-bold txt-blue">
                HOW YOU CAN HELP
              </h4>
              <p>
                <span class="uk-text-bold">ITCA</span> is currently collecting
                existing applicable tools and resources to include in the
                Indigenous Tourism Resources Portal. The types of tools and
                topic areas we are collecting are listed below.
              </p>
              <p>
                Please review the requested topics and tools below before
                submitting. Submissions will be analyzed by a team for inclusion
                in the Portal. Your resource(s) will be placed in a database and 
                considered for inclusion.</p>
              <p>
                Please note that in case you have a resource planned or under
                development but not available currently, after the initial call,
                the ITCA Portal will continue to review and accept submissions
                on a rolling basis. This is an ongoing process that will evolve
                and grow over time.
              </p>
              <p class="uk-text-center">
                <a href="https://form.jotform.com/222198401771152" class="uk-button uk-button-primary" target="_blank">
                  SUBMIT RESOURCES
                </a>
              </p>
            </div>
          </div>
        </div>
        <div id="blue-block">
          <div class="uk-section-small">
            <div class="uk-container">
              <h4 class="uk-text-center uk-text-bold">
                TOURISM TOPICS REQUESTED
              </h4>
              <div class="uk-child-width-1-3@s uk-grid" uk-grid>
                <div>
                  <ul class="uk-list">
                    <li>Business development</li>
                    <li>Capacity building</li>
                    <li>Climate resilience</li>
                    <li>Crisis and risk management</li>
                    <li>Culinary and agritourism</li>
                    <li>Cultural heritage and traditional knowledge</li>
                    <li>Cultural and creative industries</li>
                    <li>Destination governance and management</li>
                    <li>Destination marketing and promotion</li>
                    <li>Digital marketing and social media</li>
                    <li>Enterprise development</li>
                    <li>Entrepreneurship</li>
                    <li>Environmental conservation</li>
                    <li>Financial literacy</li>
                    <li>Gender</li>
                    <li>Government representation and inclusion</li>
                  </ul>
                </div>
                <div>
                  <ul class="uk-list">
                    <li>Health and wellbeing</li>
					<li>Hospitality</li>
                    <li>Indigenous leadership development</li>
					<li>Indigenous destination development</li>
                    <li>Infrastructure and facilities</li>
                    <li>Intellectual and cultural property</li>
                    <li>Inter-governmental and private sector agreements</li>
                    <li>Interpretation</li>
                    <li>Investment and finance</li>
                    <li>
                      Legislation and regulations (international, national,
                      state, provincial and tribal)
                    </li>
                    <li>Monetary impacts</li>
                    <li>Monitoring and evaluation</li>
                    <li>Networks and communities</li>
                    <li>Pandemic resilience (COVID-19)</li>
                    <li>Partnerships with non-Indigenous organizations</li>
                    <li>Product development</li>
                    <li>Property and user rights</li>
                  </ul>
                </div>
                <div>
                  <ul class="uk-list">
                    <li>Protected and managed areas</li>
                    <li>Purchasing and supply chain management</li>
                    <li>Research and surveys</li>
                    <li>Resource management</li>
					<li>Safety and security</li>
                    <li>Solid waste, wastewater and harmful substances</li>
                    <li>Stakeholder engagement</li>
                    <li>Standards and certifications</li>
                    <li>Strategic planning and action plans</li>
                    <li>Sustainable management systems</li>
                    <li>Taxes and incentives</li>
                    <li>Technology and data</li>
                    <li>Transportation and mobility</li>
                    <li>Visitor and customer satisfaction</li>
                    <li>Visitor and tour operator codes of conduct</li>
                    <li>Visitor management</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="tools">
          <div class="uk-section-small">
            <div class="uk-container">
              <h4 class="uk-text-center uk-text-bold">
                TOOLS AND TYPES OF RESOURCES REQUESTED
              </h4>
              <div class="uk-grid uk-child-width-1-3@s">
                <div>
                  <div>
                    <ul class="uk-list">
                      <li>Books</li>
                      <li>Case Studies</li>
                      <li>Good Practices</li>
                      <li>Guidelines and good practices</li>
                      <li>Indigenous tourism networks and groups</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <ul class="uk-list">
                    <li>Media</li>
                    <li>Peer-reviewed articles</li>
                    <li>Policies, legislation and regulation</li>
                    <li>Principles</li>
                    <li>Reports and white papers</li>
                  </ul>
                </div>
                <div>
                  <div>
                    <ul class="uk-list">
                    <li>Stories and interviews</li>
                      <li>Strategies and plans</li>
                      <li>Training courses</li>
                      <li>Toolkits</li>
                      <li>Webinars, presentations and videos</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="dealine">
          <div class="uk-section-small">
            <div class="uk-container">
              <div uk-grid class="uk-grid uk-grid-divider uk-child-width-1-3@s">
                <div>
                  <div>
                    <h4>DEADLINE</h4>
                    <p class="uk-margin-remove">
                      As soon as possible.<br />
                      The first call for resources will close on
                    </p>
                    <h3 class="uk-margin-remove uk-text-bold txt-red">
                      December 16, 2022
                    </h3>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>Submit</h4>
                    <p>
                      To submit tools and resources, go to
                      <a href="https://form.jotform.com/222198401771152" target="_blank">Resources Submission Form</a>
                      to share a link. All information gathered is intended to
                      support Indigenous tourism communities and will be shared
                      globally and publicly. All data and documents will include
                      the source of the information and date of the material's
                      creation.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>Contact</h4>
                    <p>
                      For any questions, please contact us at
                      <a class="email" href="mailto:itca@gwu.edu" target="_blank">
                        itca@gwu.edu
                      </a>
                      and cc: Danielle Lewis Jones at
                      <a class="email" href="mailto:ddlj0608@gwmail.gwu.edu" target="_blank">ddlj0608@gwmail.gwu.edu</a>
                    </p>
                  </div>
                </div>
                <div class="uk-width-1-1">
                  <div>
                    <p class="uk-text-center">
                      <a href="https://form.jotform.com/222198401771152" target="_blank"
                        class="uk-button uk-button-primary">SUBMIT RESOURCES</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="logos">
          <div class="uk-section-small">
            <div class="uk-container">
              <h4 class="uk-text-center uk-text-bold">MEMBERS</h4>
              <div>
                <div uk-slider="autoplay: true; autoplay-interval: 4000">
                  <div
                    class="uk-position-relative uk-visible-toggle"
                    tabindex="-1"
                  >
                  <div class="uk-slider-container uk-light">
                    <ul class="uk-slider-items uk-child-width-1-1">
                      <li>
                        <img
                          src="../assets/logos-bar1.jpg"
                          width=""
                          height=""
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src="../assets/logos-bar2.jpg"
                          width=""
                          height=""
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src="../assets/logos-bar3.jpg"
                          width=""
                          height=""
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src="../assets/logos-bar4.jpg"
                          width=""
                          height=""
                          alt=""
                        />
                      </li>
                    </ul>
                    </div>
                    <div class="uk-visible@m">
                        <a class="uk-position-center-left-out uk-position-small uk-button-primary" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                        <a class="uk-position-center-right-out uk-position-small uk-button-primary" href="#" uk-slidenav-next uk-slider-item="next"></a>
                    </div>
                  </div>
                  <ul
                    class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"
                  ></ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div class="uk-section-small">
            <div class="uk-container">
              <h4 class="uk-text-center">STEERING COMMITTEE</h4>
              <div class="uk-flex uk-flex-center">
                <ul class="uk-grid uk-text-center uk-flex uk-flex-middle">
                  <li>
                    <img src="../assets/OAS.png" alt="OAS" uk-img />
                  </li>
                  <li>
                    <img src="../assets/US-BUREAU-INDIAN-AFFAIRS.png" alt="US Bureau Indian Affairs" uk-img />
                  </li>
                  <li>
                    <img src="../assets/iits.jpg" alt="IITS" uk-img />
                  </li>
                </ul>
              </div>
              <p class="uk-text-center color-white">
                <a href="https://turismoindigena.net/" target="_blank" rel="noopener noreferrer">
                  Ver versión en Español
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  title: "Homepage Title",
  components: {},
};
</script>
<style scoped>
  html,
  body {
    font-size: 18px;
  }
  
  #home {
    background-color: #000;
  }
  .color-white a{
    color:#fff;
  }
  .container {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .body {
    background-color: #fff;
    font-size: 18px;
  }
  
  h1,
  h2,
  h3,
  h4 {
    font-family: "33535gillsansmt" !important;
  }
  
  h1 {
    font-size: 35px;
    color: #d60012;
  }
  
  h2 {
    font-size: 57px;
    color: #00798f;
  }
  
  .subtitle {
    font-size: 36px;
    line-height: 42px;
  }
  
  .txt-blue {
    color: #00798f;
  }
  
  .txt-red {
    color: #d60012;
  }
  
  .uk-list> :nth-child(n + 2) {
    margin-top: 0;
  }
  
  #description {
    background-color: #f3ebdf;
    color: #000;
  }
  
  #cta {
    background-color: #d9b57b;
    color: #000;
  }
  
  #blue-block {
    background-color: #00788e;
    color: #fff;
  }
  
  #blue-block h4 {
    color: #fff;
  }
  
  #tools {
    background-color: #d9b57b;
    color: #000;
  }
  
  #dealine a {
    color: #00788e;
  }
  
  #dealine h4 {
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
  }
  
  #logos {
    background-color: #f3ebdf;
  }
  
  #footer {
    background-color: #00788e;
    color: #fff;
  }
  
  #footer h4 {
    color: #fff;
    font-weight: bold;
  }
  
  .email {
    color: #00788e;
    text-decoration: underline;
  }
  
  .uk-button-primary {
    background-color: #d60011;
    color: #fff !important;
    border-radius: 8px;
  }
  
  .uk-button-primary:hover {
    background-color: #af0110f8;
    color: #fff !important;
    border-radius: 8px;
  }
  
  .uk-dotnav>.uk-active>a {
    background-color: #d60011;
  }
  </style>
